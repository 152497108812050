@tailwind base;

.payzip-background {
  /* background-image: url("./Assets/background.svg"); */
  /* background-repeat: repeat;
  background-size: 410px 360px;
  */
}

.react-datepicker__input-container input {
  @apply shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md;
}

.react-datepicker__tab-loop {
  @apply absolute;
}

.react-datepicker-popper {
  /* @apply absolute top-1 z-10 right-0 w-72 text-sm transform-none bg-white shadow px-3 py-2 !important; */
  @apply top-1 z-10 w-72 text-sm bg-white shadow px-3 py-2;
}

.react-datepicker-popper[data-placement="left"] {
  @apply mr-2;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-900;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-900 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-900 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-primary-800;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-primary-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-primary-500;
}

.react-datepicker__day--selected {
  @apply bg-primary-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-primary-500 text-white hover:text-gray-900 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-primary-500 text-white hover:text-gray-900 hover:bg-white;
}

tr.row-hover-effect td:first-child {
  box-shadow: inset 0 0 0 #627d98;
  transition: box-shadow 0.08s ease-in-out;
}

tr.row-hover-effect:hover td:first-child {
  box-shadow: inset 5px 0 0 #627d98;
}

@tailwind components;
@tailwind utilities;
